import React from 'react'
import Link from "../link"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row, Container } from "react-bootstrap"

function ContentHero(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var einleitung = 'Einleitung'
    var video = content.Videokomponente?.Video?.localFile.publicURL
    var hintergrund = 'fingerabdruck'
    if(content.Videokomponente?.Hintergrund === 'Welle'){
        hintergrund = 'welle'
    }
    if (locale === 'en') {
        titel = 'TitelEn'
        einleitung = 'EinleitungEn'
        if (typeof content.Videokomponente?.VideoEn?.localFile.publicURL !== "undefined") {
            video = content.Videokomponente.VideoEn.localFile.publicURL
        }
    }
    return (
        <div className={content.Unterseite ? "subhero" : "herowrapper"}>
            <div className="hero">
                <GatsbyImage className="d-none background" image={content.Hintergrundbild.localFile.childImageSharp.gatsbyImageData} alt="" style={{
                    gridArea: "1/1"
                    // You can set a maximum height for the image, if you wish.
                    // maxHeight: 600,
                }} />
                <div className="gatsby-image-wrapper background" style={{
                    gridArea: "1/1"
                    // You can set a maximum height for the image, if you wish.
                    // maxHeight: 600,
                }}>
                    {content.Hintergrundvideo ? (
                        <video autoPlay muted loop poster={content.Hintergrundbild.localFile.publicURL}>
                            <source src={content.Hintergrundvideo.localFile.publicURL} type="video/mp4" />
                            <track src="captions.vtt" kind="captions" srcLang="de" label="german captions"></track>
                        </video>
                    ) : (
                        <img src={content.Hintergrundbild.localFile.publicURL} style={{objectFit: "cover", opacity: 1}} alt=""/>
                    )}
                </div>
                <Container style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                }} >
                    <Row>
                        <Col xs="12" lg="7" className="order-xs-1 order-lg-2 photo pt-5">
                            <GatsbyImage image={content.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                        </Col>
                        <Col xs="12" lg="5" className="order-xs-2 order-lg-1 text d-flex flex-column justify-content-center">
                            <h1>{content[titel]}</h1>
                            <h3>{content[einleitung]}</h3>
                            {content.Buttons.length > 0 && content.Buttons.map((but, j) => {
                                return (
                                    but.page ? (
                                        <Link key={"herobtn" + j} to={(locale === 'en' ? '/en' : '') + but.page.navigation.path} className="btn btn-default">{but[titel]}</Link>
                                    ) : ''
                                )
                            })}
                        </Col>
                    </Row>
                    {video && !content.Unterseite && (
                    <Row className="videoshadow">
                        <Col xs="12" lg="10" className="mx-auto"></Col>
                    </Row>
                    )}
                </Container>
            </div >
            {video && (
                <div className={"video " + hintergrund}>
                    <Container>
                        <Row>
                            <Col xs="12" lg="10" className="mx-auto">
                                <video controls autoPlay muted>
                                    <source src={video} type="video/mp4" />
                                    <track src="captions.vtt" kind="captions" srcLang="de" label="german captions"></track>
                                </video>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </div>
    )
}

export default ContentHero