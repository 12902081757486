import React from 'react'
import seedrandom from "seedrandom"
import { GatsbyImage } from "gatsby-plugin-image"
import { Decoration, animations, decovariants } from '../decoration'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Col, Row, Card, Container } from "react-bootstrap"
import ReactMarkdown from 'react-markdown'
import OthebMarkdown from '../othebmarkdown'

function TeaserListeBoxed(props) {
    const content = props.content
    const locale = props.locale
    const pages = props.pages
    var titel = 'Titel'
    var einleitung = 'Einleitung'
    var cta = 'CallToAction'
    if (locale === 'en') {
        titel = 'TitelEn'
        einleitung = 'EinleitungEn'
        cta = 'CallToActionEn'
    }
    const rng = seedrandom(content.Titel);
    return (
        <Container className={"teaserliste " + content.Variante}>
            <Row>
                <Col xs="12" lg="12">
                    <h2 id={content.anchor}>
                        <Decoration top={-50} left={-100} animation={Math.floor(rng() * animations.length)} variant={decovariants[Math.floor(rng() * decovariants.length)]}/>
                        {content[titel]}
                    </h2>
                </Col>
            </Row>
            {content[einleitung]?.length > 0 && (
                <Row>
                    <Col className="mb-4" xs="12">
                        <p className="mb-0">{content[einleitung]}</p>
                        {content.Bild?.localFile.extension === 'svg' ?
                            <img src={content.Bild.localFile.publicURL} alt="" />
                            : (
                                content.Bild?.localFile.childImageSharp && (
                                    <GatsbyImage image={content.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                                )
                            )}
                    </Col>
                </Row>
            )}
            <Row className="row-cols-1 row-cols-md-2 g-4">
                {content.Icons?.map((icon, j) => {
                    var ipage = false
                    if (icon.CallToActionLink?.navigation?.id > 0 && pages.length > 0) {
                        pages.forEach(p => {
                            if (p.strapiId === icon.CallToActionLink.navigation.id) {
                                ipage = p
                            } else if (p.items.length > 0) {
                                p.items.forEach(s => {
                                    if (s.id === icon.CallToActionLink.navigation.id) {
                                        ipage = s
                                    }
                                });
                            }
                        });
                    }
                    return (
                        <Col className="mx-auto" key={"teasericon" + j}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs="12" md="2" lg="3" xl="2" className="icon">
                                            <img src={icon.Icon?.localFile.publicURL} alt="" />
                                        </Col>
                                        <Col xs="12" md="10" lg="9" xl="10" className="text">
                                            <ReactMarkdown components={OthebMarkdown}>
                                                {icon[titel]}
                                            </ReactMarkdown>
                                            <ReactMarkdown components={OthebMarkdown}>
                                                {icon[einleitung]}
                                            </ReactMarkdown>
                                            {icon[cta]?.length > 0 && (
                                                <AnchorLink to={(locale === 'en' ? '/en' : '') + ipage.path + (icon.anchor ? '#' + icon.anchor : '')}>{icon[cta]}</AnchorLink>
                                            )}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )

}

export default TeaserListeBoxed