import React from 'react'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

function Ansprechpartner(props) {
    const ansprechpartner = props.ansprechpartner
    if (ansprechpartner === null) {
        return null;
    }
    const id = props.id ? props.id : ''
    const standalone = props.standalone ? true : false
    return (
        <Container className={standalone ? 'text-center' : ''}>
            <Row className="ansprechpartner" id={id}>
                {ansprechpartner.Bild && ansprechpartner.Bild.localFile && (
                    <>
                        <Col xs="8" md="6" className="bild mt-4 mt-md-0">
                            <GatsbyImage image={ansprechpartner.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                        </Col>
                        <Col xs="4" md="6"></Col>
                    </>
                )}
                <Col xs="12">
                    <div>
                        <strong>{ansprechpartner.Vorname} {ansprechpartner.Name},</strong><br />
                        {ansprechpartner.Beschreibung?.length > 0 && (
                            <ReactMarkdown>
                                {ansprechpartner.Beschreibung}
                            </ReactMarkdown>
                        )}
                    </div>
                    <p className="comm">
                        {ansprechpartner.Email && (
                            <><a href={"mailto:" + ansprechpartner.Email} title={ansprechpartner.Email}>
                                <FontAwesomeIcon className="orange me-2 fa-inverse circled" icon={faEnvelope} alt="Email" /> {ansprechpartner.Email}
                            </a><br />
                            </>
                        )}
                        {ansprechpartner.Telefon && (
                            <a href={"tel:" + ansprechpartner.Telefon.replace(/\s/g, '')} title={ansprechpartner.Telefon}>
                                <FontAwesomeIcon className="me-2 fa-inverse circled tel" icon={faPhone} alt="Tel" /> {ansprechpartner.Telefon}
                            </a>
                        )}
                    </p>
                    <p>
                        <b>OTHEB GmbH</b><br />Norwegenkai 1<br />24143 Kiel
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default Ansprechpartner