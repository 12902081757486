import React from 'react'
import ReactMarkdown from 'react-markdown'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import OthebMarkdown from '../othebmarkdown'
import getLink from "../navigationhelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function BildModul(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    var cta = 'CallToAction'
    var ititle = 'Icontitel'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
        cta = 'CallToActionEn'
        ititle = 'IcontitelEn'
    }
    var bp = null
    return (
        <div className="bildmodul background" id={content.anchor}>
            <GatsbyImage
                className="d-none d-xl-block"
                image={content.Vollbild.localFile.childImageSharp.gatsbyImageData}
                alt={content[titel] ? content[titel] : ""}
                style={{
                    gridArea: "1/1"
                }} />
            <GatsbyImage
                className="d-none d-lg-block d-xl-none"
                image={content.VollbildTablet.localFile.childImageSharp.gatsbyImageData}
                alt={content[titel] ? content[titel] : ""}
                style={{
                    gridArea: "1/1"
                }} />
            <GatsbyImage
                className="d-block d-lg-none"
                image={content.VollbildMobile.localFile.childImageSharp.gatsbyImageData}
                alt={content[titel] ? content[titel] : ""}
                style={{
                    gridArea: "1/1"
                }} />
            {content.Bildoverlay && (
                <div className="carea">
                    <div className={"caption"}>
                        {content.Bildoverlay.Icon && (
                            <>
                                <img className="d-block mb-2" src={content.Bildoverlay.Icon.localFile.publicURL} alt="" />
                                {content.Bildoverlay[ititle] && (
                                    <h4>{content.Bildoverlay[ititle]}</h4>
                                )}
                            </>
                        )}
                        <h3>{content.Bildoverlay[titel]}</h3>
                        <ReactMarkdown components={OthebMarkdown}>
                            {content.Bildoverlay[text]}
                        </ReactMarkdown>
                        {content.Bildoverlay.CallToAction.length > 0 && content.Bildoverlay.CallToAction.map((c, i) => {
                            bp = getLink(props.pages, c)
                            return (
                                c.CallToActionLinkExtern?.length > 0 ?
                                    <a key={"kachel" + i} href={c.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={c.Bild ? 'd-block d-md-inline me-3 my-2' : 'but d-block d-md-inline me-3 my-2'}>
                                        {typeof c.Bild?.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                            <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                            </>
                                        )}
                                    </a>
                                    : <AnchorLink key={"kachel" + i} to={(locale === 'en' ? '/en' : '') + bp?.path + (c.anchor ? '#' + c.anchor : '')} className="but me-3">
                                        {typeof c.Bild?.localFile?.childImageSharp?.gatsbyImageData !== "undefined" ? (
                                            <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                            </>
                                        )}
                                    </AnchorLink>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default BildModul