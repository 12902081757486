import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import Link from "../link"
import { Col, Row, Container, Form, Button } from "react-bootstrap"
import Ansprechpartner from './ansprechpartner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Translations from '../translations'
import { useCookies } from "react-cookie"
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../../images/icons/alert.svg'

function ContentContact(props) {
    const recaptchaRef = React.useRef()
    const cookieList = ['essential', 'google']
    const [cookies, removeCookie] = useCookies(cookieList)
    const { register, handleSubmit } = useForm()
    const [error, setError] = useState(null)
    const [sent, setSent] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const token = 'hiMPSpNG50ZFdCBUvd7vmCxsGlrzHMX8'

    const content = props.content
    const locale = props.locale
    const showBanner = () => {
        removeCookie('essential', 0, {
            path: '/'
        })
    }
    var titel = 'Titel'
    var text = 'Einleitung'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'EinleitungEn'
    }
    const onSubmit = async data => {
        data.captcha = await recaptchaRef.current.executeAsync();
        data.pid = props.pid
        data.cid = content.id
        setSent(false)
        setError(false)
        setSubmitting(true)
        fetch('/mail/index.php', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "AuthorizationBearer": "Bearer " + token,
                "Authorization": "Basic " + window.btoa("otheb:winter2021")
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                console.log(response)
                throw new Error(Translations[locale].error_sending_email);
            }
            return response.json()
        }).then(data => {
            setSent(true);
            setSubmitting(false);
        }).catch((e) => {
            setError(e.name + ': ' + e.message);
            setSubmitting(false);
        });
    }

    return (
        <Container className="kontaktformular">
            <Row>
                <Col xs="12" lg="5" className="order-1 order-lg-2">
                    <Ansprechpartner ansprechpartner={content.ansprechpartner} />
                </Col>
                <Col id={content.anchor} xs="12" lg="6" className="order-1 order-lg-1">
                    {content[titel]?.length > 0 && (
                        <h2>{content[titel]}</h2>
                    )}
                    {content[text]?.length > 0 && (
                        <p className="einleitung">{content[text]}</p>
                    )}
                    {sent ? (
                        <div className="alert alert-success my-2 text-center" role="alert">
                            <span>{Translations[locale].contact_success}</span>
                        </div>
                    ) : (cookies['google'] ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridFirma">
                                        <Form.Label>{Translations[locale].unternehmen}</Form.Label>
                                        <Form.Control name="firma" placeholder={Translations[locale].unternehmen} {...register("firma")} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridFunktion">
                                        <Form.Label>{Translations[locale].position}</Form.Label>
                                        <Form.Control name="funktion" placeholder={Translations[locale].position} {...register("funktion")} />
                                    </Form.Group>
                                    <Form.Group xs="12" as={Col} controlId="formGridName">
                                        <Form.Label>{Translations[locale].name} *</Form.Label>
                                        <Form.Control name="name" placeholder={Translations[locale].name} {...register("name", { "required": true })} />
                                    </Form.Group>
                                    <Form.Group xs="12" as={Col} controlId="formGridEmail">
                                        <Form.Label>{Translations[locale].email} *</Form.Label>
                                        <Form.Control name="email" type="email" placeholder={Translations[locale].email} {...register("email", {
                                            required: true, pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: Translations[locale].email_format
                                            }
                                        })} />
                                    </Form.Group>
                                    <Form.Group xs="12" as={Col} controlId="formGridTelefon">
                                        <Form.Label>{Translations[locale].telefon}</Form.Label>
                                        <Form.Control name="telefon" placeholder={Translations[locale].telefon} {...register("telefon", {  })} />
                                    </Form.Group>
                                    <Form.Group xs="12" as={Col} controlId="formGridNachricht">
                                        <Form.Label>{Translations[locale].message} *</Form.Label>
                                        <Form.Control name="message" as="textarea" placeholder={Translations[locale].message} {...register("message", { "required": true })} />
                                    </Form.Group>
                                </Row>
                                {error && (
                                    <div className="alert alert-danger mt-2" role="alert">{error}</div>
                                )}
                                <p className="legal mb-0 mt-2">* {Translations[locale].required}</p>
                                <p className="legal">{Translations[locale].datenschutzhinweis} <Link to="/datenschutz">{Translations[locale].hier}</Link></p>
                                <Button variant="primary" type="submit" className="mt-3">
                                    {submitting ? (
                                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                                    ) : (Translations[locale].absenden)
                                    }
                                </Button>
                                <ReCAPTCHA
                                    size="invisible"
                                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                                    ref={recaptchaRef}
                                />
                            </Form>
                        ) : (
                            <div className="m-0 text-center d-flex flex-column justify-content-center dropzone">
                                <p><Alert /></p>
                                <p>{Translations[locale].please_accept_1} <button className="textlink" onClick={showBanner}>{Translations[locale].please_accept_2}</button> {Translations[locale].please_accept_recaptcha}</p>
                            </div>
                        )
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default ContentContact
