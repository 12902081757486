import React from 'react'
import Link from "../link"
import ReactMarkdown from 'react-markdown'
import { Col, Row, Container } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import Translations from '../translations'

function ContentContactShort(props) {
    const content = props.content
    const locale = props.locale
    const id = props.id ? props.id : ''
    return (
        <Container className="contactshort">
            <Row className="justify-content-center">
                <Col id={content.anchor} xs="12" lg="9">
                    <h2 id={content.anchor}>Kontakt aufnehmen</h2>
                    <Row id={id}>
                        <Col xs="12" lg="6">
                            <Row className="ansprechpartner">
                                <Col xs="8" sm="6" lg="4" className="bild mb-sm-2 badge ps-1">
                                    {content.ansprechpartner.Bild && content.ansprechpartner.Bild.localFile && (
                                        <GatsbyImage image={content.ansprechpartner.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                                    )}
                                </Col>
                                <Col xs="4" md="6" lg="8"></Col>
                                <Col xs="12" sm="12" className="mb-sm-2">
                                    <div class="no-endabstand">
                                        <strong>{content.ansprechpartner.Vorname} {content.ansprechpartner.Name},</strong><br />
                                        {content.ansprechpartner.Beschreibung.length > 0 && (
                                            <ReactMarkdown>
                                                {content.ansprechpartner.Beschreibung}
                                            </ReactMarkdown>
                                        )}
                                    </div>
                                    <p className="comm">
                                        <a href={"mailto:" + content.ansprechpartner.Email} title={content.ansprechpartner.Email}>
                                            <FontAwesomeIcon className="orange me-2 ps-1" icon={faEnvelope} alt="Email" /> {content.ansprechpartner.Email}
                                        </a><br />
                                        <a href={"tel:" + content.ansprechpartner.Telefon.replace(/\s/g, '')} title={content.ansprechpartner.Telefon}>
                                            <FontAwesomeIcon className="me-2 fa-inverse circled tel" icon={faPhone} alt="Tel" /> {content.ansprechpartner.Telefon}
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" lg="6" className="legal">
                            <p>
                                Nutzen Sie bitte unser <Link to="/Kontakt">Kontaktformular</Link>! {Translations[locale].datenschutzhinweis} <Link to="/datenschutz">{Translations[locale].hier}</Link>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
export default ContentContactShort