import * as React from "react"
import seedrandom from "seedrandom"
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row, Container } from "react-bootstrap"
import OthebMarkdown from '../othebmarkdown'
import { Decoration, animations, decovariants } from '../decoration'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import getLink  from "../navigationhelper"

function ContentSplitModul(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    var cta = 'CallToAction'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
        cta = 'CallToActionEn'
    }
    var order1 = 'order-1 order-lg-1 justify-content-center d-flex flex-column';
    var order2 = 'order-2 order-lg-2';

    if (content.Bildausrichtung === 'rechts') {
        order1 = 'order-1 order-lg-2 justify-content-center d-flex flex-column';
        order2 = 'order-2 order-lg-1';
    }
    const pages = props.pages
    const rng = seedrandom(content.Titel)
    var page = false
    if (content.CallToActionLink?.id > 0 && pages.length > 0) {
        pages.forEach(p => {
            if (p.strapiId === content.CallToActionLink?.navigation.id) {
                page = p
            } else if (p.items.length > 0) {
                p.items.forEach(s => {
                    if (s.id === content.CallToActionLink?.navigation.id) {
                        page = s
                    }
                });
            }
        });
    }
    return (
        <Container className={"rte splitmodul " + content.Bildausrichtung}>
            <Row>
                <Col className={order1} xs="12" lg="6">
                    {content.Bild.localFile.extension === 'svg' ? 
                        <img src={content.Bild.localFile.publicURL} alt=""/>
                     : (
                        content.Bild.localFile.childImageSharp && (
                            <GatsbyImage image={content.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                        )
                    )}
                </Col>
                <Col className={order2 + " text"} xs="12" lg="6">
                    <h2 id={content.anchor} name={content.anchor}>
                        <Decoration top={-50} right={-100} animation={Math.floor(rng() * animations.length)} variant={decovariants[Math.floor(rng() * decovariants.length)]}/>
                        {content[titel]}
                    </h2>
                    <ReactMarkdown components={OthebMarkdown}>
                        {content[text]}
                    </ReactMarkdown>
                    {content.CallToActionButtons.length > 0 && content.CallToActionButtons.map((calltoaction, j) => {
                        const bp = getLink(pages, calltoaction)
                        return (
                            calltoaction.CallToActionLinkExtern?.length > 0 ?
                                <a key={"splitmodulcta-" + j} href={calltoaction.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={calltoaction.Bild ? 'me-3' : 'btn btn-default'}>
                                    {typeof calltoaction.Bild.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                        <GatsbyImage image={calltoaction.Bild.localFile.childImageSharp.gatsbyImageData} alt={calltoaction[cta]} />
                                    ) : (
                                        calltoaction[cta]
                                    )}
                                </a>
                                : <AnchorLink key={"splitmodulcta-" + j} to={(locale === 'en' ? '/en' : '') + bp?.path + (calltoaction.anchor ? '#' + calltoaction.anchor : '')} className="btn btn-default">{calltoaction[cta]}</AnchorLink>
                        );
                    })}
                    {content.CallToActionButtons.length < 1 && (page || content.CallToActionLinkExtern?.length > 0) && (
                        content.CallToActionLinkExtern?.length > 0 ?
                            <a href={content.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={content.Linkbild ? 'me-3' : 'btn btn-default'}>
                                {typeof content.Linkbild.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                    <GatsbyImage image={content.Linkbild.localFile.childImageSharp.gatsbyImageData} alt={content[cta]} />
                                ) : (
                                    content[cta]
                                )}
                            </a> : <AnchorLink to={(locale === 'en' ? '/en/' : '/') + page.path} className="btn btn-default">{content[cta]}</AnchorLink>
                    )}
                    <Decoration left={-200} bottom={0} animation={Math.floor(rng() * animations.length)} variant={decovariants[Math.floor(rng() * decovariants.length)]}/>
                </Col>
            </Row>
        </Container>
    )
}

export default ContentSplitModul