import * as React from "react"
import ReactMarkdown from 'react-markdown'
import { Col, Row, Container } from "react-bootstrap"
import OthebMarkdown from '../othebmarkdown'

function ContentRichText(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    var text1 = 'Text1'
    var text2 = 'Text2'
    var text3 = 'Text3'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
        text1 = 'Text1En'
        text2 = 'Text2En'
        text3 = 'Text3En'
    }
    var parts = [text, text1, text2, text3]
    var s = 0
    parts.forEach(p => {
        if(content[p]?.trim().length > 0){
            s++
        }
    });
    var spalten = 12
    if(s > 1){
        spalten = spalten / s
    }
    var ausrichtung = 'text-left'
    if(content.Ausrichtung === 'zentriert'){
        ausrichtung = 'text-center'
    }
    var ausrichtung1 = 'text-left'
    if(content.Ausrichtung1 === 'zentriert'){
        ausrichtung1 = 'text-center'
    }
    var ausrichtung2 = 'text-left'
    if(content.Ausrichtung2 === 'zentriert'){
        ausrichtung2 = 'text-center'
    }
    var ausrichtung3 = 'text-left'
    if(content.Ausrichtung3 === 'zentriert'){
        ausrichtung3 = 'text-center'
    }
    return (
        <Container className="rte">
            <Row>
                <Col sm="12">
                    <h2 id={content.anchor}>
                        {content[titel]}
                    </h2>
                </Col>
                <Col sm="12" lg={spalten} className={ausrichtung}>
                    <ReactMarkdown components={OthebMarkdown}>
                        {content[text]}
                    </ReactMarkdown>
                </Col>
                {content[text1]?.length > 0 && (
                    <Col sm="12" lg={spalten} className={ausrichtung1}>
                        <ReactMarkdown components={OthebMarkdown}>
                            {content[text1]}
                        </ReactMarkdown>
                    </Col>
                )}
                {content[text2]?.length > 0 && (
                    <Col sm="12" lg={spalten} className={ausrichtung2}>
                        <ReactMarkdown components={OthebMarkdown}>
                            {content[text2]}
                        </ReactMarkdown>
                    </Col>
                )}
                {content[text3]?.length > 0 && (
                    <Col sm="12" lg={spalten} className={ausrichtung3}>
                        <ReactMarkdown components={OthebMarkdown}>
                            {content[text3]}
                        </ReactMarkdown>
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default ContentRichText