import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Col, Row, Card, Container } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import OthebMarkdown from '../othebmarkdown'
import { ConditionalWrapper } from '../conditionalwrapper'

function TeaserListeNormal(props) {
    const content = props.content
    const locale = props.locale
    const pages = props.pages
    var titel = 'Titel'
    var einleitung = 'Einleitung'
    var cta = 'CallToAction'
    if (locale === 'en') {
        titel = 'TitelEn'
        einleitung = 'EinleitungEn'
        cta = 'CallToActionEn'
    }
    var page = null
    if (content.CallToActionLink?.id > 0 && pages.length > 0) {
        pages.forEach(p => {
            if (p.strapiId === content.CallToActionLink.id) {
                page = p
            } else if (p.items.length > 0) {
                p.items.forEach(s => {
                    if (s.id === content.CallToActionLink.id) {
                        page = s
                    }
                });
            }
        });
    }
    const zuvieleIcons = content.Icons.length > 4
    return (
        <div className={"teaserliste " + content.Variante}>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h2 id={content.anchor}>
                            {content[titel]}
                        </h2>
                    </Col>
                </Row>
                {content[einleitung]?.length > 0 && (
                    <Row>
                        <Col className="mx-auto" xs="12" lg="8">
                            <p className={zuvieleIcons ? 'text-start mb-0' : "mb-0"}>{content[einleitung]}</p>
                            {content.Bild?.localFile.extension === 'svg' ?
                                <img src={content.Bild.localFile.publicURL} alt="" />
                                : (
                                    content.Bild?.localFile.childImageSharp && (
                                        <GatsbyImage image={content.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                                    )
                                )}
                        </Col>
                    </Row>
                )}
                <Row className="mx-auto icons row-cols-1 row-cols-md-2 g-4">
                    <ConditionalWrapper condition={true} wrapper={children => <Col className="mx-auto" xs="12" lg="8"><Row>{children}</Row></Col>}>
                        {content.Icons?.map((icon, j) => {
                            var ipage = false
                            if (icon.CallToActionLink?.navigation?.id > 0 && pages.length > 0) {
                                pages.forEach(p => {
                                    if (p.strapiId === icon.CallToActionLink.navigation.id) {
                                        ipage = p
                                    } else if (p.items.length > 0) {
                                        p.items.forEach(s => {
                                            if (s.id === icon.CallToActionLink.navigation.id) {
                                                ipage = s
                                            }
                                        });
                                    }
                                });
                            }
                            return (
                                <Col xs="12" lg={zuvieleIcons ? 12 : 6} className="mt-0 mb-3" key={"teasericon" + j}>
                                    <Card>
                                        <Card.Body className="py-0">
                                            <Row>
                                                <Col xs={zuvieleIcons ? 2 : 4} className="icon">
                                                    <img src={icon.Icon?.localFile.publicURL} alt="" />
                                                </Col>
                                                <Col xs={zuvieleIcons ? 10 : 8} className="text d-flex flex-column">
                                                    <ReactMarkdown components={OthebMarkdown}>
                                                        {icon[titel]}
                                                    </ReactMarkdown>
                                                    {icon[cta]?.length > 0 && (
                                                        <AnchorLink to={(locale === 'en' ? '/en' : '') + ipage.path + (icon.anchor ? '#' + icon.anchor : '')}>{icon[cta]}</AnchorLink>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })}
                    </ConditionalWrapper>
                </Row>
                {page && (
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <AnchorLink to={(locale === 'en' ? '/en/' : '/') + page.path} className="btn btn-default">{content[cta]}</AnchorLink>
                        </Col>
                    </Row>
                )}
            </Container>
        </div >
    )
}

export default TeaserListeNormal