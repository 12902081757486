import React, { useState } from 'react'
import Link from "../link"
import { Container, Row, Col, Button } from "react-bootstrap"
import Translations from '../translations'
import DownloadImage from '../../images/icons/dl.svg'
import DownloadJpgImage from '../../images/icons/dl-jpg.svg'
import DownloadPdfImage from '../../images/icons/dl-pdf.svg'
import DownloadZipImage from '../../images/icons/dl-zip.svg'

function Pressespiegel(props) {
    const content = props.content
    const items = props.items
    const locale = props.locale
    const [showItems, setShowItems] = useState(5)
    const nextItems = 3
    var titel = 'Titel'
    if (locale === 'en') {
        titel = 'TitelEn'
    }
    const addItems = () => {
        setShowItems(showItems + nextItems)
    }
    return (
        <Container className="pressespiegel">
            <h2 id={content.anchor} className="header">
                {Translations[locale].pressespiegel}
            </h2>
            <div className="spiegel">
            {items.slice(0, showItems).map((item, i) => {
                var icon = <DownloadImage alt="Download"/>
                if (item.Datei) {
                    if (item.Datei.ext === '.png' || item.Datei.ext === '.jpg' || item.Datei.ext === '.webp' || item.Datei.ext === '.svg') {
                        icon = <DownloadJpgImage alt="Download"/>
                    } else if (item.Datei.ext === '.zip') {
                        icon = <DownloadZipImage alt="Download"/>
                    } else if (item.Datei.ext === '.pdf') {
                        icon = <DownloadPdfImage alt="Download"/>
                    }
                }
                return (
                    <Row key={"pressespiegel" + i}>
                        <Col xs="3" md="2" className="align-items-center pb-4 datum d-flex">
                            <span className="d-none d-md-block">{item.Datum}</span>
                        </Col>
                        <Col xs="9" md="10">
                            <Row className="box">
                                <div className="badge"></div>
                                <Col xs="12" lg="10">
                                    <p className="d-block d-md-none datum">{item.Datum}</p>
                                    {item.Link ? (
                                        <Link to={item.Link} target="_blank">
                                            <strong>{item[titel]}</strong>
                                        </Link>
                                    ) : (
                                        <strong>{item[titel]}</strong>
                                    )}
                                    <p>{item.Medium}</p>
                                </Col>
                                <Col xs="12" lg="2" className="d-flex justify-md-content-end">
                                    {item.Datei && (
                                        <a rel="noreferrer" target="_blank" href={item.Datei.localFile.publicURL}>
                                            {icon}
                                        </a>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
            {showItems < items.length && (
                <Row>
                    <Col xs="2"></Col><Col xs="10">
                        <Button onClick={addItems}>{Translations[locale].olderArticles}</Button>
                    </Col>
                </Row>
            )}
            </div>
        </Container>
    )
}

export default Pressespiegel