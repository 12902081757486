import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import OthebMarkdown from '../othebmarkdown'
import DownloadImage from '../../images/icons/dl.svg'
import DownloadJpgImage from '../../images/icons/dl-jpg.svg'
import DownloadPdfImage from '../../images/icons/dl-pdf.svg'
import DownloadZipImage from '../../images/icons/dl-zip.svg'

function DownloadModul(props) {
    const content = props.content
    const downloads = props.downloads
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
    }
    return (
        <Container className="downloadmodul">
            <h2 id={content.anchor} className="header">
                Downloads
            </h2>
            <Row>
                <Col xs="12" lg="4" className="order-1 order-lg-2 d-none d-lg-block ps-4">
                    <ReactMarkdown components={OthebMarkdown}>
                        {content[text]}
                    </ReactMarkdown>
                </Col>
                <Col xs="12" lg="8" className="order-2 order-lg-1">
                    <Tabs defaultActiveKey="downloads0">
                        {downloads.length > 0 && downloads.map((kategorie, i) => {
                            if (kategorie.downloads.length === 0) {
                                return null
                            }
                            return (
                                <Tab key={"downloads" + i} eventKey={"downloads" + i} title={kategorie[titel]}>
                                    <Row className="d-block d-lg-none border-0 mt-0">
                                        <Col xs="12">
                                            <ReactMarkdown components={OthebMarkdown}>
                                                {content[text]}
                                            </ReactMarkdown>
                                        </Col>
                                    </Row>
                                    {kategorie.downloads.map((download, j) => {
                                        var icon = <DownloadImage height="76" alt="Download"/>
                                        if(download.Datei.ext === '.png' || download.Datei.ext === '.jpg' || download.Datei.ext === '.webp' || download.Datei.ext === '.svg'){
                                            icon = <DownloadJpgImage height="76" alt="Download"/>
                                        } else if(download.Datei.ext === '.zip'){
                                            icon = <DownloadZipImage height="76" alt="Download"/>
                                        } else if(download.Datei.ext === '.pdf'){
                                            icon = <DownloadPdfImage height="76" alt="Download"/>
                                        }
                                        return (
                                            <Row key={"download" + j}>
                                                <Col className="py-4 px-5 d-flex" xs="12" md="3">
                                                {typeof download.Thumbnail?.localFile?.childImageSharp?.gatsbyImageData !== "undefined" ? (
                                                    <GatsbyImage image={download.Thumbnail.localFile.childImageSharp.gatsbyImageData} alt="" />
                                                ) : (
                                                    typeof download.Datei?.localFile?.childImageSharp?.gatsbyImageData !== "undefined" ? (
                                                        <GatsbyImage image={download.Datei.localFile.childImageSharp.gatsbyImageData} alt="" />
                                                    ) : ""
                                                )}
                                                </Col>
                                                <Col xs="12" md="9" lg="6" className="d-flex align-self-center">
                                                    <p>
                                                        <strong>{download[titel]}</strong><br />
                                                        {download[text]}
                                                    </p>
                                                </Col>
                                                <Col xs="12" md="12" lg="3" className="d-flex align-self-center flex-column align-items-center align-items-md-end align-items-sm-start mb-md-3 mb-sm-3">
                                                    <a rel="noreferrer" target="_blank" href={download.Datei.localFile.publicURL}>
                                                        {icon}
                                                    </a>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Tab>
                            )
                        })}
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
}

export default DownloadModul