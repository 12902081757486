import React, { useState, useEffect } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AccordionModul from "../components/content/accordion"
import BewerbungsModul from "../components/content/bewerbungsformular"
import DatenschutzModul from "../components/content/datenschutz"
import DownloadModul from "../components/content/downloads"
import BildModul from "../components/content/bildmodul"
import CarouselModul from "../components/content/carousel"
import ContentBulletlist from "../components/content/bulletlist"
import ContentContact from "../components/content/contact"
import ContentClientContact from "../components/content/clientcontact"
import ContentContactShort from "../components/content/contactshort"
import ContentHero from "../components/content/hero"
import ContentIframe from "../components/content/iframe"
import ContentNews from "../components/content/news"
import ContentRichText from "../components/content/richtext"
import ContentSplitModul from "../components/content/splitmodul"
import KachelModul from "../components/content/kachelmodul"
import Pressespiegel from "../components/content/pressespiegel"
import TeaserListeNormal from "../components/content/teasernormal"
import TeaserListeBoxed from "../components/content/teaserboxed"
import ContentVideo from "../components/content/video"
import ContentZitate from "../components/content/zitate"
import ContentUnknown from "../components/content/unknown"
console.log(`%c


██████  ████████ ██   ██ ███████ ██████
██    ██    ██    ██   ██ ██      ██   ██
██    ██    ██    ███████ █████   ██████ 
██    ██    ██    ██   ██ ██      ██   ██
 ██████     ██    ██   ██ ███████ ██████


`, "color: #d65a22");
function ContentPage(props) {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(false)
  }, []);

  const { allStrapiNavigation, strapiPage, allStrapiNeuigkeiten, allStrapiDownloadKategorie, allStrapiWebsiteTextbaustein, allStrapiPressespiegel } = props.data
  const keywords = typeof strapiPage?.keywords !== "undefined" && strapiPage?.keywords !== null ? strapiPage.keywords.split(',') : []
  return (
    <SSRProvider>
      <Layout isLoading={isLoading} locale={props.pageContext.locale} pages={allStrapiNavigation.nodes} textbausteine={allStrapiWebsiteTextbaustein.nodes} activePage={strapiPage?.navigation.path}>
        <svg className="gradient">
          <radialGradient id="orangeGradient" r="150%" cx="30%" cy="107%">
            <stop stopColor="#d65a22" offset="0" />
            <stop stopColor="#ffab40" offset="1" />
          </radialGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="orangeLinearGradient">
            <stop stopColor="#FFAB40" offset="0%" />
            <stop stopColor="#D65A22" offset="100%" />
          </linearGradient>
        </svg>
        <Seo title={props.pageContext.locale === 'en' ? strapiPage?.TitelEn : strapiPage?.Titel} keywords={keywords} description={strapiPage?.description} locale={props.pageContext.locale} />
        {strapiPage?.Inhalte.map((inhalt, j) => {
          switch (inhalt.strapi_component) {
            case "website-content.hero":
              return <ContentHero key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.rich-text":
              return <ContentRichText key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.accordion":
              return <AccordionModul key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.carousel":
              return <CarouselModul key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.datenschutz":
              return <DatenschutzModul key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.kachel-modul":
              return <KachelModul key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.split-modul":
              return <ContentSplitModul key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.teaser-liste":
              if (inhalt.Variante === 'boxed') {
                return <TeaserListeBoxed key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
              } else {
                return <TeaserListeNormal key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
              }
            case "website-content.bulletlist":
              return <ContentBulletlist key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.kontaktformular":
              return <ContentContact key={"content-" + j} content={inhalt} locale={props.pageContext.locale} pid={parseInt(strapiPage?.id?.replace('Page_', ''))} />
            case "website-content.klienten-kontaktformular":
              return <ContentClientContact key={"content-" + j} content={inhalt} locale={props.pageContext.locale} pid={parseInt(strapiPage?.id?.replace('Page_', ''))} />
            case "website-content.zitate":
              return <ContentZitate key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.bild-modul":
              return <BildModul key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} />
            case "website-content.video":
              return <ContentVideo key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.iframe":
              return <ContentIframe key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.news-teaser":
              return <ContentNews key={"content-" + j} content={inhalt} pages={allStrapiNavigation.nodes} locale={props.pageContext.locale} news={allStrapiNeuigkeiten} />
            case "website-content.ansprechpartner":
              return <ContentContactShort key={"content-" + j} content={inhalt} locale={props.pageContext.locale} />
            case "website-content.downloads":
              return <DownloadModul key={"content-" + j} content={inhalt} locale={props.pageContext.locale} downloads={allStrapiDownloadKategorie.nodes} />
            case "website-content.pressespiegel":
              return <Pressespiegel key={"content-" + j} content={inhalt} locale={props.pageContext.locale} items={allStrapiPressespiegel.nodes} />
            case "website-content.bewerbungsformular":
              return <BewerbungsModul key={"content-" + j} content={inhalt} locale={props.pageContext.locale} pid={parseInt(strapiPage.id.replace('Page_', ''))} />
            default:
              return <ContentUnknown key={"content-" + j} component={inhalt.strapi_component} locale={props.pageContext.locale} />
          }
        }
        )}
      </Layout>
    </SSRProvider>
  )
}

export default ContentPage
export const query = graphql`
  query($id: Int) {
    strapiPage(navigation: {id: {eq: $id}}) {
        id
        Titel
        TitelEn
        Inhalte {
          id
          anchor
          Text1
          Text1En
          Text2
          Text2En
          Text3
          Text3En
          Ausrichtung
          Ausrichtung1
          Ausrichtung2
          Ausrichtung3
          Akkordioneintraege{
            Text
            TextEn
            Titel
            TitelEn
          }
          Bildoverlay{
            Text
            TextEn
            Titel
            TitelEn
            Icon {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icontitel
            IcontitelEn
            CallToAction {
              anchor
              CallToAction
              CallToActionEn
              CallToActionLinkIntern{
                navigation {
                  id
                  path
                }
              }
              CallToActionLinkExtern
              Bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      height: 46
                    )
                  }
                }
              }
            }
          }
          Eintrag {
            anchor
            Text
            TextEn
            Titel
            TitelEn
          }
          Carouseleintraege{
            Text
            TextEn
            Titel
            TitelEn
            Bild {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Bilder {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icon {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icontitel
            IcontitelEn
            CallToAction {
              anchor
              CallToAction
              CallToActionEn
              CallToActionLinkIntern{
                navigation {
                  id
                  path
                }
              }
              CallToActionLinkExtern
              Bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      height: 46
                    )
                  }
                }
              }
            }
          }
          ansprechpartner {
            Beschreibung
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Email
            Name
            Telefon
            Vorname
          }
          Email
          Buttons {
            Titel
            TitelEn
            page {
              navigation {
                path
              }
            }
          }
          strapi_component
          CallToAction
          CallToActionEn
          CallToActionLink {
            id
            Titel
            TitelEn
            navigation {
              id
            }
          }
          CallToActionLinkExtern
          Linkbild {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 46
                )
              }
            }
          }
          CallToActionButtons {
            anchor
            CallToAction
            CallToActionEn
            CallToActionLinkIntern{
              navigation {
                id
                path
              }
            }
            CallToActionLinkExtern
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 46
                  )
                }
              }
            }
          }
          Einleitung
          EinleitungEn
          Positionen {
            Eintrag
            EintragEn
          }
          Zitate {
            Name
            NameEn
            Zitat
            ZitatEn
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 110
                  )
                }
              }
            }
          }
          Variante
          Icons {
            anchor
            Titel
            TitelEn
            Einleitung
            EinleitungEn
            CallToAction
            CallToActionEn
            CallToActionLink {
              id
              Titel
              TitelEn
              navigation {
                id
                path
              }
            }
            Icon {
              localFile {
                publicURL
              }
            }
          }
          Bild {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                )
              }
            }
          }
          Bildausrichtung
          Vollbild {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          VollbildTablet {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          VollbildMobile {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          Hintergrundbild {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          Hintergrundvideo {
            localFile {
              publicURL
            }
          }
          Text
          TextEn
          TitelEn
          Unterseite
          kategorie {
            id
          }
          Titel
          Video {
            localFile {
              publicURL
            }
          }
          VideoEn {
            localFile {
              publicURL
            }
          }
          URL,
          width,
          height,
          Hintergrund
          Videokomponente {
            Titel
            TitelEn
            Hintergrund
            Video {
              localFile {
                publicURL
              }
            }
            VideoEn {
              localFile {
                publicURL
              }
            }
          }
        }
        description
        keywords,
        navigation{
            path
        }
      }
      allStrapiWebsiteTextbaustein {
        nodes {
          Text
          TextEn
          Titel
        }
      }
      allStrapiNeuigkeiten(
         sort: {
          fields: [Sticky, Datum], 
          order: [DESC, DESC]
        }) {
        nodes {
          Einleitung
          EinleitungEn
          Slug
          Titel
          TitelEn
          link_intern{
            navigation {
              id
              path
            }
          }
          kategorie {
            id
            Button
            ButtonEn
          }
          Bilder {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 500
                )
              }
            }
          }
        }
      }
      allStrapiPressespiegel(sort: {
        fields: [Datum], 
        order: [DESC, DESC]
      }) {
        nodes {
          Datei {
            ext
            localFile {
              publicURL
            }
          }
          Datum(formatString: "DD.MM.YYYY")
          Medium
          Link
          Titel
          TitelEn
        }
      }
      allStrapiDownloadKategorie {
        nodes {
          Titel
          TitelEn
          downloads {
            Text
            TextEn
            Titel
            TitelEn
            Datei {
              ext
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
      allStrapiNavigation {
        nodes {
          items {
            id
            slug
            path
            related {
              Titel
              TitelEn
              hideInMenu
              showInFooter
            }
          }
          path
          strapiId
          related {
            Titel
            TitelEn
            hideInMenu
            showInFooter
          }
          slug
        }
    }
  }
`