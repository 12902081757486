import React from 'react'
import ReactMarkdown from 'react-markdown'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Carousel } from "react-bootstrap"
import OthebMarkdown from '../othebmarkdown'
import getLink from "../navigationhelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeftLong, faArrowRightLong, faArrowRight } from '@fortawesome/free-solid-svg-icons'

function CarouselModul(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    var cta = 'CallToAction'
    var ititle = 'Icontitel'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
        cta = 'CallToActionEn'
        ititle = 'IcontitelEn'
    }
    var bp = null
    return (
        <div className="carouselmodul">
            <Container id={content.anchor}>
                <Carousel prevIcon={<FontAwesomeIcon className="gray" icon={faArrowLeftLong} alt="" />} nextIcon={<FontAwesomeIcon className="gray" icon={faArrowRightLong} alt="" />}>
                    {content.Carouseleintraege.length > 0 && content.Carouseleintraege.map((car, j) => {
                        return (
                            <Carousel.Item key={"carouselitem" + j}>
                                {car.Bild && (
                                    <GatsbyImage className="d-block w-100" image={car.Bild.localFile.childImageSharp.gatsbyImageData} alt="" />
                                )}
                                <Carousel.Caption>
                                    {car.Icon && (
                                        <>
                                            <img className="d-block mb-2" src={car.Icon.localFile.publicURL} alt="" />
                                            {car[ititle] && (
                                                <h4>{car[ititle]}</h4>
                                            )}
                                        </>
                                    )}
                                    {car[titel] && (
                                        <h3>{car[titel]}</h3>
                                    )}
                                    <ReactMarkdown components={OthebMarkdown}>
                                        {car[text]}
                                    </ReactMarkdown>
                                    {car.Bilder.length > 0 && car.Bilder.map((b, i) => {
                                        return (
                                            <GatsbyImage className="mb-3" key={"kbild"+i} image={b.localFile.childImageSharp.gatsbyImageData} alt="" />
                                        )
                                    })}
                                    {car.CallToAction.length > 0 && car.CallToAction.map((c, i) => {
                                        bp = getLink(props.pages, c)
                                        return (
                                            c.CallToActionLinkExtern?.length > 0 ?
                                                <a key={"carouselitemcta" + i} href={c.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={c.Bild ? 'me-3' : 'but me-3'}>
                                                    {typeof c.Bild?.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                                        <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                                        </>
                                                    )}
                                                </a>
                                                : <AnchorLink key={"kachel" + i} to={(locale === 'en' ? '/en' : '') + bp?.path + (c.anchor ? '#' + c.anchor : '')} className="but me-3">
                                                    {typeof c.Bild?.localFile?.childImageSharp?.gatsbyImageData !== "undefined" ? (
                                                        <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                                        </>
                                                    )}
                                                </AnchorLink>
                                        )
                                    })}
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Container>
        </div>
    )
}

export default CarouselModul