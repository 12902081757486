import React, { useState } from 'react'
import Link from "../link"
import { useForm } from "react-hook-form"
import { Col, Row, Container, Form, Button } from "react-bootstrap"
import ReactMarkdown from 'react-markdown'
import OthebMarkdown from '../othebmarkdown'
import { FileUploader } from "react-drag-drop-files"
import Translations from '../translations'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from "react-cookie"
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../../images/icons/alert.svg'
const fileTypes = ["JPG", "PNG", "PDF"]

function BewerbungsModul(props) {
    const recaptchaRef = React.useRef()
    const cookieList = ['essential', 'google']
    const [cookies, removeCookie] = useCookies(cookieList)
    const { register, handleSubmit } = useForm()
    const [error, setError] = useState(null)
    const [sent, setSent] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [file, setFile] = useState(null)
    const token = 'hiMPSpNG50ZFdCBUvd7vmCxsGlrzHMX8'

    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
    }
    const showBanner = () => {
        removeCookie('essential', 0, {
            path: '/'
        })
    }
    const handleChange = (file) => {
        setFile(file)
    }
    const onSubmit = async data => {
        const captcha = await recaptchaRef.current.executeAsync();
        const formData = new FormData();
        if (file !== null) {
            for (let i = 0; i < file.length; i++) {
                formData.append("files[]", file.item(i))
            }
        }
        formData.append("vorname", data.vorname)
        formData.append("name", data.name)
        formData.append("email", data.email)
        formData.append("telefon", data.telefon)
        formData.append("pid", props.pid)
        formData.append("cid", content.id)
        formData.append("captcha", captcha)
        setSent(false);
        setError(false)
        setSubmitting(true)
        fetch('/mail/bewerbung.php', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Accept": "application/json",
                "AuthorizationBearer": "Bearer " + token,
                "Authorization": "Basic " + window.btoa("otheb:winter2021")
            },
            body: formData
        }).then(response => {
            if (!response.ok) {
                console.log(response)
                throw new Error(Translations[locale].error_sending_email);
            }
            return response.json()
        }).then(data => {
            if (data.code !== 200) {
                throw new Error(Translations[locale].error_sending_email);
            }
            setSent(true);
            setSubmitting(false);
        }).catch((e) => {
            setError(e.name + ': ' + e.message);
            setSubmitting(false);
        });
    }
    return (
        <div className="bewerbung">
            <Container>
                <Row>
                    <Col xs="12" lg="6">
                        <h2 id={content.anchor} className="header">
                            {content[titel]}
                        </h2>
                        <ReactMarkdown components={OthebMarkdown}>
                            {content[text]}
                        </ReactMarkdown>
                    </Col>
                    <Col xs="12" lg="6">
                        {sent ? (
                            <p>{Translations[locale].bewerbung_sent}</p>
                        ) : (cookies['google'] ? (
                            <>
                                <FileUploader classes="dropzone" handleChange={handleChange} name="file" types={fileTypes} multiple={true} children={
                                    <Row>
                                        <Col xs="12" className="text-center">
                                            <FontAwesomeIcon icon={faCloudArrowUp} />
                                            <p className="py-3 px-5 mx-5">{Translations[locale].dragndrop}</p>
                                            <div className="btn btn-primary">{Translations[locale].searchfiles}</div>
                                        </Col>
                                    </Row>
                                } />
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Form.Group xs="12" as={Col} controlId="formGridVorname">
                                                <Form.Label>{Translations[locale].vorname} *</Form.Label>
                                                <Form.Control vorname="name" placeholder={Translations[locale].vorname} {...register("vorname", { "required": true })} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Form.Group xs="12" as={Col} controlId="formGridName">
                                                <Form.Label>{Translations[locale].name} *</Form.Label>
                                                <Form.Control name="name" placeholder={Translations[locale].name} {...register("name", { "required": true })} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <Form.Group xs="12" as={Col} controlId="formGridEmail">
                                                <Form.Label>{Translations[locale].email} *</Form.Label>
                                                <Form.Control name="email" type="email" placeholder={Translations[locale].email} {...register("email", {
                                                    required: true, pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: Translations[locale].email_format
                                                    }
                                                })} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Form.Group xs="12" as={Col} controlId="formGridTelefon">
                                                <Form.Label>{Translations[locale].telefon}</Form.Label>
                                                <Form.Control name="telefon" placeholder={Translations[locale].telefon} {...register("telefon", { "required": false })} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <p className="legal">{Translations[locale].datenschutzhinweis} <Link to="/datenschutz">{Translations[locale].hier}</Link></p>
                                            <p className="legal">* {Translations[locale].required}</p>
                                            {error && (
                                                <p className="alert alert-danger">{Translations[locale].error_sending_email}</p>
                                            )}
                                        </Col>
                                        <Col xs="12" className="d-flex justify-content-end">
                                            <Button variant="primary" type="submit">
                                                {submitting ? (
                                                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                                                ) : (Translations[locale].absenden)
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                    <ReCAPTCHA
                                        size="invisible"
                                        sitekey={process.env.RECAPTCHA_SITE_KEY}
                                        ref={recaptchaRef}
                                    />
                                </Form>
                            </>
                        ) : (
                            <div className="m-0 text-center d-flex flex-column justify-content-center dropzone">
                                <p><Alert /></p>
                                <p>{Translations[locale].please_accept_1} <button className="textlink" onClick={showBanner}>{Translations[locale].please_accept_2}</button> {Translations[locale].please_accept_recaptcha}</p>
                            </div>
                        )
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default BewerbungsModul