import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col, Row, Container, Card, Carousel } from "react-bootstrap"

function ContentZitate(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var zitat = 'Zitat'
    var name = 'Name'
    if (locale === 'en') {
        titel = 'TitelEn'
        zitat = 'ZitatEn'
        name = 'NameEn'
    }
    return (
        <div className="zitate">
            <Container>
                <Row>
                    <Col className="mx-auto" xs="12" xl="8">
                        <Card>
                            <h2 id={content.anchor}>
                                {content[titel]}
                            </h2>
                            <Carousel indicators={false} interval={8000}>
                                {content.Zitate?.length > 0 && content.Zitate.map((z, j) => {
                                    return (
                                        <Carousel.Item key={"zitat" + j}>
                                            <Row>
                                                <Col xs="12" className="bild">
                                                    <GatsbyImage image={z.Bild.localFile.childImageSharp.gatsbyImageData} alt="" height={110}/>
                                                </Col>
                                                <Col xs="12" className="zitat">
                                                    <p className="quote">{z[zitat]}</p>
                                                    <p>{z[name]}</p>
                                                </Col>
                                            </Row>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default ContentZitate