import React from 'react'
import ReactMarkdown from 'react-markdown'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col } from "react-bootstrap"
import OthebMarkdown from '../othebmarkdown'
import getLink from "../navigationhelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Kachel(props) {
    const width = props.width
    const content = props.content
    const locale = props.locale
    const format = props.format
    const num = props.num
    var titel = 'Titel'
    var text = 'Text'
    var cta = 'CallToAction'
    var ititle = 'Icontitel'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
        cta = 'CallToActionEn'
        ititle = 'IcontitelEn'
    }
    var bp = null
    return (
        <Col className={"kacheln " + format} xs="12" lg={width}>
            <GatsbyImage className="background" image={content.Bild.localFile.childImageSharp.gatsbyImageData} alt="" style={{
                gridArea: "1/1"
            }} />
            <div className="carea">
                <div className={"caption caption-" + num}>
                    {content.Icon && (
                        <>
                            <img className="d-block mb-2" src={content.Icon.localFile.publicURL} alt="" />
                            {content[ititle] && (
                                <h4>{content[ititle]}</h4>
                            )}
                        </>
                    )}
                    <h3>{content[titel]}</h3>
                    <ReactMarkdown components={OthebMarkdown}>
                        {content[text]}
                    </ReactMarkdown>
                    {content.Bilder?.length > 0 && content.Bilder.map((b, i) => {
                        return (
                            <GatsbyImage className="mb-3" key={"kbild"+i} image={b.localFile.childImageSharp.gatsbyImageData} alt="" />
                        )
                    })}
                    {content.CallToAction.length > 0 && content.CallToAction.map((c, i) => {
                        bp = getLink(props.pages, c)
                        return (
                            c.CallToActionLinkExtern?.length > 0 ?
                                <a key={"kachel" + i} href={c.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={c.Bild ? 'd-block d-md-inline me-3 my-2' : 'but d-block d-md-inline me-3 my-2'}>
                                    {typeof c.Bild?.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                        <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                        </>
                                    )}
                                </a>
                                : <AnchorLink key={"kachel" + i} to={(locale === 'en' ? '/en' : '') + bp?.path + (c.anchor ? '#' + c.anchor : '')} className="but me-3">
                                    {typeof c.Bild?.localFile?.childImageSharp?.gatsbyImageData !== "undefined" ? (
                                        <GatsbyImage image={c.Bild.localFile.childImageSharp.gatsbyImageData} alt={c[cta]} />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon className="orange mt-1 me-2" icon={faArrowRight} alt="" /> {c[cta]}
                                        </>
                                    )}
                                </AnchorLink>
                        )
                    })}
                </div>
            </div>
        </Col>
    )
}
export default Kachel