import * as React from "react"
import { Col, Row, Container } from "react-bootstrap"
import { useCookies } from "react-cookie"
import Translations from '../translations'
import Alert from '../../images/icons/alert.svg'

function ContentIframe(props) {
    const cookieList = ['essential', 'google']
    const [cookies, removeCookie] = useCookies(cookieList)
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    if (locale === 'en') {
        titel = 'TitelEn'
    }
    const isGoogle = content.URL.indexOf('google') !== -1 || content.URL.indexOf('youtube') !== -1
    const showBanner = () => {
        removeCookie('essential', 0, {
            path: '/',
            sameSite: true,
            secure: true,
            expires: new Date()
        })
    }
    return (
        <div className={"video iframe"}>
            <Container>
                <Row>
                    <Col xs="12" lg="10" className="mx-auto text-center schatten">
                        {isGoogle && cookies['google'] ? (
                            <iframe id={content.anchor} title={content[titel]} src={content.URL} width={content.width} height={content.height} allowFullScreen={true} loading="lazy"/>
                        ) : (
                            <div className="m-0 text-center kartenbg d-flex flex-column justify-content-center">
                                <p><Alert/></p>
                                <p>{Translations[locale].please_accept_1} <button className="textlink" onClick={showBanner}>{Translations[locale].please_accept_2}</button> {Translations[locale].please_accept_3}</p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContentIframe