import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import Kachel from "./kachel"

function KachelModul(props) {
    const content = props.content
    const locale = props.locale
    const pages = props.pages
    var titel = 'Titel'
    if (locale === 'en') {
        titel = 'TitelEn'
    }
    return (
        <div className="kachelmodul">
            {content[titel]?.length > 0 && (
                <Row>
                    <Col sm="12">
                        <h2 className="header">
                            {content[titel]}
                        </h2>
                    </Col>
                </Row>
            )}
            <Container id={content.anchor}>
                <Row>
                    <Kachel num={1} format={content.Carouseleintraege.length === 2 ? 'portrait' : "landscape"} width={content.Carouseleintraege.length === 2 ? 6 : 12} content={content.Carouseleintraege[0]} locale={locale} pages={pages} />
                    {content.Carouseleintraege[1] && (
                        <Kachel num={2} format="portrait"width={6} content={content.Carouseleintraege[1]} locale={locale} pages={pages}/>
                    )}
                    {content.Carouseleintraege[2] && (
                        <Kachel num={3} format="portrait" width={6} content={content.Carouseleintraege[2]} locale={locale} pages={pages}/>
                    )}
                    {content.Carouseleintraege[3] && (
                        <Kachel num={4} format="landscape" width={12} content={content.Carouseleintraege[3]} locale={locale} pages={pages}/>
                    )}
                </Row>
            </Container>
        </div>
    )
}

export default KachelModul