import React from 'react';
import ReactMarkdown from 'react-markdown'
import { Col, Row, Container, Accordion } from "react-bootstrap"
import OthebMarkdown from '../othebmarkdown'
import PlusIcon from '../../images/icons/icons-plus.svg'
import MinusIcon from '../../images/icons/icons-minus.svg'

function AccordionModul(props) {
    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Text'

    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'TextEn'
    }
    return (
        <Container className="accordionmodul">
            <Row className="justify-content-center">
                <Col id={content.anchor} xs="12" lg="8">
                    {content[titel]?.length > 0 && (
                        <Row>
                            <Col sm="12">
                                <h2 className="header">
                                    {content[titel]}
                                </h2>
                            </Col>
                        </Row>
                    )}
                    <Accordion defaultActiveKey={-1}>
                    {content.Akkordioneintraege.length > 0 && content.Akkordioneintraege.map((acc, j) => {
                        return (                        
                            <Accordion.Item key={"accordionitem"+j} eventKey={j}>
                                <Accordion.Header><PlusIcon className="plus" /> <MinusIcon className="minus" /> {acc[titel]}</Accordion.Header>
                                <Accordion.Body>
                                    <ReactMarkdown components={OthebMarkdown}>
                                    {acc[text]}
                                    </ReactMarkdown>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    )
}

export default AccordionModul