import React, { useState } from 'react'
import Link from "../link"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Container, Card, Row, Col, Button } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Translations from '../translations'
import scrollToElement from 'scroll-to-element'
import { getNewsLink }  from "../navigationhelper"

function ContentNews(props) {
    const [page, setPage] = useState(0)
    const perPage = 9
    const startIndex = page * perPage
    const content = props.content
    const news = props.news.nodes
    const filteredNews = news.filter((n) => { return n.kategorie.id === content.kategorie?.id })
    const slicedNews = filteredNews.slice(startIndex, startIndex + perPage)
    const showPagination = filteredNews.length > perPage
    const locale = props.locale
    
    var titel = 'Titel'
    var cta = 'Button'
    if (locale === 'en') {
        titel = 'TitelEn'
        cta = 'ButtonEn'
    }
    var slug = "/meldungen/"
    if (content.kategorie?.id === 2){
        slug = "/blog/"
    }
    const meldungenPath = (locale === 'en' ? '/en' : '') + slug

    const goPrev = () => {
        setPage(page+1)
        scrollToElement('#newsPagination'+content.id, {
            offset: -250,
            duration: 300
        })
    }
    const goNext = () => {
        setPage(Math.max(0,page-1))
        scrollToElement('#newsPagination'+content.id, {
            offset: -250,
            duration: 300
        })
    }
    return (
        <div className="news">
            <Container>
                <h2 id={content.anchor}>{content[titel]}</h2>
                <Row id={"newsPagination"+content.id} className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {slicedNews.map((n, j) => {
                        const bp = getNewsLink(props.pages, n)
                        return (
                            <Col key={"news-" + j} className="mb-4">
                                <Card>
                                    {n.Bilder[0] ? (
                                        <GatsbyImage image={n.Bilder[0].localFile.childImageSharp.gatsbyImageData} alt="" className="card-img-top" />
                                    ) : (
                                        <StaticImage src="../../../static/images/Strandsteg_Hintergrund.jpg" width={500} alt="" className="card-img-top" />
                                    )}
                                    <Card.Body>
                                        <h3>{n[titel]}</h3>
                                        {bp ? (
                                            <AnchorLink to={(locale === 'en' ? '/en' : '') + bp.path}>{n.kategorie[cta]}</AnchorLink>
                                        ): (
                                            <Link to={meldungenPath + n.Slug}>{n.kategorie[cta]}</Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                {showPagination && (
                    <Row>
                        <Col className="d-flex justify-content-center ">
                            {page > 0 && (
                                <Button className="me-3" onClick={goNext}>{Translations[locale].next}</Button>
                            )}
                            <Button onClick={goPrev}>{Translations[locale].prev}</Button>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>

    )

}
export default ContentNews