import React, { useState } from 'react';
import { Col, Row, Container, Button } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import Translations from '../translations'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import PlusIcon from '../../images/icons/icons-plus.svg'
import getLink  from "../navigationhelper"

function ContentBulletlist(props) {
    const [expanded, setExpanded] = useState(false);

    const content = props.content
    const locale = props.locale
    var titel = 'Titel'
    var text = 'Einleitung'
    var eintrag = 'Eintrag'
    var cta = 'CallToAction'
    if (locale === 'en') {
        titel = 'TitelEn'
        text = 'EinleitungEn'
        eintrag = 'EintragEn'
        cta = 'CallToActionEn'
    }
    return (
        <Container className="bulletlistmodul">
            <Row className="justify-content-center">
                <Col xs="12" lg="8">
                    <Row>
                        <Col sm="12">
                            <h2 id={content.anchor}>
                                {content[titel]}
                            </h2>
                            <p className={expanded ? 'expanded' : ''}>{content[text]}</p>
                            <Button className="d-block d-lg-none" onClick={() => { setExpanded(!expanded) }}>
                                {expanded ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                                {Translations[locale].read_more}
                            </Button>
                        </Col>
                    </Row>
                    <ul>
                        {content.Positionen.length > 0 && content.Positionen.map((pos, j) => {
                            return (
                                <li key={"bl" + j}>
                                    <PlusIcon /> {pos[eintrag]}
                                </li>
                            )
                        })}
                    </ul>
                    {content.CallToActionButtons.length > 0 && content.CallToActionButtons.map((calltoaction, j) => {
                        const bp = getLink(props.pages, calltoaction)
                        return (
                            calltoaction.CallToActionLinkExtern?.length > 0 ?
                                <a key={"bulletlistcta-"+j} href={calltoaction.CallToActionLinkExtern} rel="noreferrer" target="_blank" className={calltoaction.Bild ? 'me-3' : 'btn btn-default me-3'}>
                                    {typeof calltoaction.Bild.localFile.childImageSharp.gatsbyImageData !== "undefined" ? (
                                        <GatsbyImage image={calltoaction.Bild.localFile.childImageSharp.gatsbyImageData} alt={calltoaction[cta]} />
                                    ) : (
                                        calltoaction[cta]
                                    )}
                                </a>
                                : <AnchorLink key={"bulletlistcta-"+j} to={(locale === 'en' ? '/en' : '') + bp?.path + (calltoaction.anchor ? '#'+calltoaction.anchor : '')} className="btn btn-default me-3">{calltoaction[cta]}</AnchorLink>
                        );
                    })}
                </Col>
            </Row>
        </Container>
    )
}

export default ContentBulletlist