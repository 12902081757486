import * as React from "react"
import { Alert} from "react-bootstrap"
import Translations from '../translations'

function ContentUnknown(props) {

    return (
        <Alert className="alert-danger">
            <h4>{Translations[props.locale].unknown_content_type}</h4>
            <p>"{props.component}" {Translations[props.locale].is_undefined}!</p>
        </Alert>
    )
}

export default ContentUnknown