import * as React from "react"
import { Col, Row, Container } from "react-bootstrap"
import seedrandom from "seedrandom"
import { Decoration, animations } from '../decoration'

function ContentVideo(props) {
    const content = props.content
    const locale = props.locale
    const rng = seedrandom(content.Titel)
    var titel = 'Titel'
    var video = content.Video?.localFile.publicURL

    if (locale === 'en') {
        titel = 'TitelEn'
        if (typeof content.VideoEn?.localFile.publicURL !== "undefined") {
            video = content.VideoEn.localFile.publicURL
        }
    }
    var hintergrund = 'fingerabdruck'
    if(content.Hintergrund === 'Welle'){
        hintergrund = 'welle'
    }
    return (
        <div className={"video " + hintergrund}>
            <Container>
                <h2 id={content.anchor}>
                    <Decoration variant="triplet" left={-40} top={-60} animation={Math.floor(rng() * animations.length)} />
                    {content[titel]}
                </h2>
                <Row>
                    <Col xs="12" lg="10" className="mx-auto">
                        <video controls>
                            <source src={video} type="video/mp4" />
                            <track src="captions.vtt" kind="captions" srcLang="de" label="german captions"></track>
                        </video>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContentVideo